// import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import { TfiMore } from "react-icons/tfi";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { TbLicense, TbBrandGmail } from "react-icons/tb";

export const menus = [
  { name: "Home", link: "/", icon: AiOutlineHome },
  // { name: "How to use?", link: "/", icon: TfiHelpAlt },
  { name: "Feedbacks", link: "/comments", icon: HiOutlineChatBubbleLeftRight },

  {
    name: "No data is collected",
    link: "/",
    icon: MdOutlinePrivacyTip,
  },
  { name: "Absolutely free for now", link: "/", icon: TbLicense },
  { name: "info@faalentech.com", link: "/", icon: TbBrandGmail },
  {
    name: "Other Products",
    otherProducts: [
      { productName: "Image Optimizer", productLink: "/" },
      { productName: "Document Converters", productLink: "/" },
    ],
    link: "#",
    icon: TfiMore,
    more: true,
  },
];
