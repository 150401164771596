/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { HiMenuAlt3 } from "react-icons/hi";
import { Link } from "react-router-dom";
import { menus } from "./Menus/menus";

const SideBar = ({ openSubMenu, setOpenSubMenu, openNav, setOpenNav }) => {
  return (
    <div className="flex flex-1 gap-6 relative z-50  mr-9">
      <div className="fixed transition-[ease-in]">
        <div
          className={`bg-black min-h-screen h-screen  text-white ${
            !openNav ? "w-16" : "w-72"
          }`}
        >
          <div className="flex justify-end py-3">
            <HiMenuAlt3
              size={26}
              className="cursor-pointer"
              onClick={() => setOpenNav(!openNav)}
            />
          </div>
          <div className="flex flex-col gap-6 relative cursor-pointer mt-4 px-2">
            {menus.map((menu, i) => (
              <Link
                to={menu.link}
                key={i}
                style={{ transitionDelay: `${i + 3}0ms` }}
                className={`group flex items-center gap-3.5 font-medium p-2 hover:bg-gray-900 rounded-md`}
                onClick={() => {
                  menu.more && setOpenSubMenu(!openSubMenu);
                }}
              >
                <div>{React.createElement(menu.icon, { size: "20" })}</div>
                <h2
                  style={{ transitionDelay: `${i + 3}00ms` }}
                  className={`whitespace-pre duration-500 relative transition-all ${
                    !openNav && " opacity-0  -translate-x-16"
                  }`}
                >
                  {menu.name}
                </h2>
                {menu.more && (
                  <div
                    className={`flex flex-col gap-4 bg-black absolute top-[100%] left-10 border border-slate-700 rounded-md p-4 ${
                      openSubMenu
                        ? "block w-[14rem] opacity-100 right-48 duration-400 transition-all"
                        : "hidden"
                    }`}
                  >
                    {menu.otherProducts.map((product, i) => (
                      <Link
                        key={i}
                        to={product.productLink}
                        className="hover:bg-gray-900 rounded-md p-2"
                      >
                        <h2>{product.productName}</h2>
                      </Link>
                    ))}
                  </div>
                )}
                <h2
                  className={`${
                    openNav ? "hidden" : "block"
                  } absolute right-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}
                >
                  {menu.name}
                </h2>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
