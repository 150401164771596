import React from "react";

const Dots = () => {
  return (
    <div class="dots">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
const Loading = () => {
  return (
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
const SpinStretch = () => {
  return <div className="spin-stretch"></div>;
};

export { Loading, Dots, SpinStretch };
