import "./App.css";
import { useState } from "react";
import AnimatedRoutes from "./components/AnimatedRoutes";
import SideBar from "./components/SideBar";
import { CounterContext } from "./Context/CounterContext";
import { QueryClientProvider } from "@tanstack/react-query";
import { QueryClient } from "@tanstack/react-query";
function App() {
  const [downloads, setDownloads] = useState({
    numberOfDownloads: null,
    isLoaded: false,
  });

  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <CounterContext.Provider value={{ downloads, setDownloads }}>
        <div
          className="App overflow-hidden"
          onClick={() => {
            openSubMenu && setOpenSubMenu(false);
            openNav && setOpenNav(false);
          }}
        >
          <div className="">
            <SideBar
              openSubMenu={openSubMenu}
              setOpenSubMenu={setOpenSubMenu}
              openNav={openNav}
              setOpenNav={setOpenNav}
            />
            <AnimatedRoutes />
          </div>
        </div>
      </CounterContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
