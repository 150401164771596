import React, { lazy, Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { SpinStretch } from "./Loading";

const SignPad = lazy(() => import("./SignPad"));
const Comments = lazy(() => import("./Feedback/Comments"));
const Home = lazy(() => import("./Home"));
const AnimatedRoutes = () => {
  const location = useLocation();
  const LoadingComponents = () => {
    return (
      <div className="flex flex-col justify-center items-center h-full w-full fixed text-center right-0 bottom-0 top-0 left-5 ">
        <SpinStretch />
        <h1 className="text-white">Loading components please wait...</h1>
      </div>
    );
  };
  return (
    <AnimatePresence>
      <Suspense fallback={<LoadingComponents />}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />}>
            <Route index element={<Home />} />
          </Route>
          <Route path="comments" element={<Comments />} />
          <Route path="signpad" element={<SignPad />} />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
